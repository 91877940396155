.about {
  overflow: hidden;
  padding: 0 50px;
}
.about-main-container {
  display: flex;
  justify-content: center;
  align-items: stretch;
  max-width: 1440px;
  margin: 0 auto;
  gap: 40px;
}
.about-content {
  background-color: #ebebebdb;
  backdrop-filter: blur(3px);
  width: 50%;
  border-radius: 15px;
  padding: 25px;
}
.about-main-container h2 {
  margin: 0;
  font-size: 2.5rem;
}
.about-content p {
  font-size: 1.1rem;
  line-height: 30px;
}

.about-tech {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ebebebdb;
  backdrop-filter: blur(3px);
  width: 50%;
  border-radius: 15px;
  padding: 25px;
}
.tech-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.tech-item-container {
  width: 33.3%;
  margin: 15px auto;
}
.tech-item {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  background: white;
  border-radius: 15px;
  box-shadow: rgb(0 0 0) 6px 6px 0px;
  border: 1px solid rgb(0, 0, 0);
  transition: all 0.3s;
}
.tech-item:hover svg {
  fill: #fb7f3b;
}
.tech-item:hover {
  transform: translateY(-5px);
  box-shadow: #fb7f3b 6px 6px 0px;
  border: 1px solid #fb7f3b;
}
.tech-item svg {
  width: 100%;
  height: 100%;
  transition: all 0.3s;
  padding: 20px;
}

@media only screen and (max-width: 992px) {
  .about {
    padding: 0 20px;
  }
  .about-main-container {
    flex-direction: column;
  }
  .about-content {
    width: auto;
  }
  .about-tech {
    width: auto;
  }
}
@media only screen and (max-width: 600px) {
  .tech-container {
    gap: 10px;
  }
}
