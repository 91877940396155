.banner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  z-index: 100;
  position: relative;
  margin-left: 50px;
}

.banner-row-center {
  display: flex;
  justify-content: space-between;
  height: 290px;
}
.banner-circle {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.banner-text-container {
  border-radius: 50%;
  border: 2px #fb7f3b solid;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 270px;
  height: 270px;
  transition: all 0.3s ease;
}
.banner-text-container:hover {
  width: 290px;
  height: 290px;
}
.banner-text {
  font-size: 1rem;
  background-color: white;
  display: flex;
  gap: 10px;
  font-weight: 600;
  flex-direction: column;
  justify-content: center;
  width: 230px;
  height: 230px;
  padding: 10px;
  border-radius: 50%;
}
.banner-text span {
  margin-left: 15px;
}
.banner-row-botton {
  display: flex;
  align-items: center;
  overflow: hidden;
}

/* ---------------------------------- */
/* Text Animation PopUp Start*/
/* ---------------------------------- */
.title-effect {
  margin: 0;
  font-size: 10vw;
  text-align: center;

  background-color: #ff2525;
  background-image: linear-gradient(
    147deg,
    #ff4f4f 0%,
    #fda81e 40%,
    #ff4f4f 100%
  );

  background-size: 200% auto;
  animation: shine 3s linear infinite;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@keyframes shine {
  to {
    background-position: 200% center;
  }
}
.animated-container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.row-title {
  font-size: 11vw;
  font-weight: 500;

  display: inline-block;
  white-space: nowrap;
}
.row-letter {
  position: relative;
  display: inline-block;
}

.row-title {
  overflow: hidden;
}
/* ---------------------------------- */
/* Text Animation PopUp Finish*/
/* ---------------------------------- */

@media only screen and (max-width: 1100px) {
  .banner-circle {
    display: none;
  }
  .banner-row-center {
    height: auto;
  }
}
